import {
  CustomerEngagement,
  DataScienceSolutions,
  FeedMarketing,
  Fulfillment,
  GrowthMarketing,
  MarketplaceManagement,
  MarketplaceServices,
  Reporting,
  Storefront,
  UnifiedAnalytics,
} from '../AppAndServices.constants'
import { IAppOrService, IEnabledCards, IEnabledServices } from '../AppsAndServices.types'

export const createCardRanker = (
  defaultRankedCards: IAppOrService[],
  rank?: (enabledMap: IEnabledServices, cardMix: IAppOrService[]) => IAppOrService[],
) => {
  let cardMix = defaultRankedCards

  return {
    filterEnabled: (enabledMap: IEnabledCards) => {
      cardMix = cardMix?.filter(({ title }) => !enabledMap[title])
    },
    rank: (enabledMap: IEnabledServices) => {
      cardMix = rank ? rank(enabledMap, cardMix) : cardMix
    },
    getCards: () => cardMix,
  }
}

export const basicCardRanker = () =>
  createCardRanker([
    Storefront,
    MarketplaceManagement,
    FeedMarketing,
    UnifiedAnalytics,
    Reporting,
    GrowthMarketing,
    CustomerEngagement,
    DataScienceSolutions,
    MarketplaceServices,
    Fulfillment,
  ])

export const dynamicCardRanker = () =>
  createCardRanker(
    [
      MarketplaceManagement,
      FeedMarketing,
      UnifiedAnalytics,
      GrowthMarketing,
      Fulfillment,
      Storefront,
    ],
    ({ mcmEnabled, dfwEnabled, uaEnabled, ffEnabled, sfEnabled }, cardmix) => {
      let top3 = cardmix.slice(0, 3)

      const getFilteredCards = (cardTitles: string[]) => {
        return cardmix.filter((card) => cardTitles.includes(card.title))
      }

      if (mcmEnabled) {
        top3 = getFilteredCards([FeedMarketing.title, GrowthMarketing.title, Fulfillment.title])
      } else if (sfEnabled) {
        top3 = getFilteredCards([
          MarketplaceManagement.title,
          FeedMarketing.title,
          Reporting.title,
        ])
      } else if (uaEnabled) {
        top3 = getFilteredCards([
          GrowthMarketing.title,
          FeedMarketing.title,
          MarketplaceManagement.title,
        ])
      } else if (ffEnabled) {
        top3 = getFilteredCards([
          Reporting.title,
          MarketplaceManagement.title,
          GrowthMarketing.title,
        ])
      } else if (dfwEnabled) {
        top3 = getFilteredCards([
          MarketplaceManagement.title,
          FeedMarketing.title,
          Reporting.title,
        ])
      }

      return Array.from(new Set([...top3, ...cardmix]))
    },
  )
