import { Services } from '@brand-console/types'
import { slugify } from '@brand-console/utilities/slugify'
import { CustomerEngagementIcon } from '@cart/ui/icons/CustomerEngagementIcon'
import { DataScienceIcon } from '@cart/ui/icons/DataScienceIcon'
import { FeedMarketingIcon } from '@cart/ui/icons/FeedMarketingIcon'
import { FulfillmentIcon } from '@cart/ui/icons/FulfillmentIcon'
import { GrowthCapitalIcon } from '@cart/ui/icons/GrowthCapitalIcon'
import { GrowthMarketingIcon } from '@cart/ui/icons/GrowthMarketingIcon'
import { MarketplaceManagementIcon } from '@cart/ui/icons/MarketplaceManagementIcon'
import { MarketplaceServicesIcon } from '@cart/ui/icons/MarketplaceServicesIcon'
import { StorefrontIcon } from '@cart/ui/icons/StorefrontIcon'
import { UnifiedAnalyticsIcon } from '@cart/ui/icons/UnifiedAnalyticsIcon'

import { AppMenuItem } from './AppsDropdown.types'

export const MenuItems: { [key: string]: AppMenuItem } = {
  mcm: {
    title: 'Marketplace Management',
    icon: <MarketplaceManagementIcon />,
    link: `/${slugify(Services.MARKETPLACE_MANAGEMENT)}`,
  },
  sf: {
    title: 'Storefront',
    icon: <StorefrontIcon />,
    link: `/${slugify(Services.STOREFRONT)}`,
  },
  dfw: {
    title: 'Feed Marketing',
    icon: <FeedMarketingIcon />,
    link: `/${slugify(Services.FEED_MARKETING)}`,
  },
  ua: {
    title: 'Unified Analytics',
    icon: <UnifiedAnalyticsIcon />,
    link: `/${slugify(Services.UNIFIED_ANALYTICS)}`,
  },
  rpt: {
    title: 'Reporting (Beta)',
    icon: <UnifiedAnalyticsIcon />,
    link: `/${slugify(Services.REPORTING)}`,
  },
  ff: {
    title: 'Fullfillment',
    icon: <FulfillmentIcon />,
    link: `/${slugify(Services.FULFILLMENT)}`,
  },
}

export const getDiscoverItems = ({ shouldShowGrowthCapital, shouldShowFulfillment }) =>
  [
    {
      title: 'Growth Marketing',
      icon: <GrowthMarketingIcon />,
      link: `/${slugify(Services.GROWTH_MARKETING)}`,
      desc: 'Cover all your business’ bases with strategy, SEO, creative services, and more',
    },
    {
      title: 'Customer Engagement',
      icon: <CustomerEngagementIcon />,
      link: `/${slugify(Services.CUSTOMER_ENGAGEMENT)}`,
      desc: 'Leverage Cart’s agents to tackle omnichannel customer support',
    },
    {
      title: 'Data Science Solutions',
      icon: <DataScienceIcon />,
      link: `/${slugify(Services.DATA_SCIENCE_SOLUTIONS)}`,
      desc: 'Apply machine learning and AI to acquire, convert, and fulfill shopper demand at scale',
    },
    {
      title: 'Marketplace Services',
      icon: <MarketplaceServicesIcon />,
      link: `/${slugify(Services.MARKETPLACE_SERVICES)}`,
      desc: 'Reach new audiences with full-service solutions for world-leading Marketplaces',
    },
    ...(shouldShowGrowthCapital
      ? [
          {
            title: 'Growth Capital',
            icon: <GrowthCapitalIcon />,
            link: `/${slugify(Services.GROWTH_CAPITAL)}`,
            desc: 'Access equity-free capital to help fund your marketing and inventory costs',
          },
        ]
      : []),
    ...(shouldShowFulfillment
      ? [
          {
            title: 'Fulfillment',
            icon: <FulfillmentIcon />,
            link: `/${slugify(Services.FULFILLMENT)}`,
            desc: 'Ship products faster with our nationwide fulfillment network.',
          },
        ]
      : []),
  ] as const
