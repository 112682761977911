import { App as UnifiedAnalyticsApp } from '@brand-console/module-unified-analytics'
import { ISidebarItem, Services } from '@brand-console/types'
import {
  CustomerEngagementIcon,
  DataScienceIcon,
  FeedMarketingIcon,
  FulfillmentIcon,
  GrowthCapitalIcon,
  GrowthMarketingIcon,
  MarketplaceManagementIcon,
  MarketplaceServicesIcon,
  StorefrontIcon,
  UnifiedAnalyticsIcon,
} from '@cart/ui'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import slugster from 'slugster'

import { FeedMarketing } from '../../components/FeedMarketing'
import { Logout } from '../../components/Logout'
import { MarketplaceManagement } from '../../components/MarketplaceManagement'
import { Orders as CoreOrders, Shipments as CoreShipments } from '../../components/Orders'
import { Storefront } from '../../components/Storefront'
import { AdminTools } from '../AdminTools'
import { CannyFeatureRequest } from '../FeatureRequests/FeatureRequests'
import { FreeToolsRoutes } from '../FreeToolsRoutes/FreeToolsRoutes'
import { Homepage } from '../Homepage'
import { LoginSignUpRoutes } from '../LoginSignUpRoutes'
import { OrganizationSelectionRoute } from '../OrganizationSelectionRoute/OrganizationSelectionRoute'
import { CreateOrganization } from '../OrganizationsRoutes/CreateOrganization/CreateOrganization'
import { CustomerEngagementRoute } from '../ProductPages/CustomerEngagementRoute'
import { DataScienceRoute } from '../ProductPages/DataScienceRoute'
import { FulfillmentRoute } from '../ProductPages/FulfillmentRoute'
import { GrowthCapitalRoute } from '../ProductPages/GrowthCapitalRoute'
import { GrowthMarketingRoute } from '../ProductPages/GrowthMarketingRoute'
import { MarketplaceServicesRoute } from '../ProductPages/MarketplaceServicesRoute'
import { SettingsRoute } from '../SettingsRoute/SettingsRoute'
import { StatusRoute } from '../StatusRoute'
import { StorefrontEmailSms } from '../StorefrontEmailSms'
import { INavigationProps } from './RoutesConfig.types'
import { Reporting } from '../../components/Reporting'

export const getSiteNavigation = async ({
  hasAdminToolsEnabled = false,
  hasSFEnabled = false,
  hasMCMEnabled = false,
  hasUAEmailReportsEnabled = false,
  hasUAAlertsEnabled = false,
  hasUAChannelMappingEnabled = false,
  hasUANewMyConnectorsEnabled = false,
  shouldShowGrowthCapital = false,
  hasMerchantToolsEnabled = false,
}: INavigationProps = {}): Promise<ISidebarItem[]> => {
  return [
    {
      component: Homepage,
      exactRoute: true,
      footer: true,
      label: 'Home',
      link: '/',
      route: ['/', '/solutions-services'],
      children: [
        {
          component: FulfillmentRoute,
          footer: false,
          icon: <FulfillmentIcon />,
          label: Services.FULFILLMENT,
          link: '/fulfillment',
          route: ['/fulfillment'],
          sidebar: false,
        },
        {
          component: CoreOrders,
          footer: true,
          icon: <FontAwesomeIcon icon={solid('shopping-cart')} />,
          label: 'Orders',
          link: '/orders',
          route: ['/orders/*'],
          sidebar: true,
        },
        {
          component: CoreShipments,
          footer: true,
          icon: <FontAwesomeIcon icon={solid('truck')} />,
          label: 'Shipments',
          link: '/shipments',
          route: ['/shipments/*'],
          sidebar: true,
        },
        {
          component: StatusRoute,
          route: ['/status'],
        },
        {
          component: CreateOrganization,
          route: ['/organization/new'],
        },
        {
          component: LoginSignUpRoutes,
          route: ['/login', '/signup'],
        },
        {
          component: Logout,
          route: '/logout',
        },
        {
          component: OrganizationSelectionRoute,
          route: '/organization-selection',
        },
        {
          component: CannyFeatureRequest,
          route: '/feature-requests',
        },
        {
          component: SettingsRoute,
          footer: true,
          icon: <FontAwesomeIcon icon={solid('cog')} />,
          label: 'Settings',
          link: '/settings',
          route: ['/settings/*'],
          sidebar: true,
          children: [
            {
              icon: <FontAwesomeIcon icon={solid('user-circle')} />,
              label: 'Profile',
              link: '/settings/profile',
              sidebar: true,
              footer: true,
            },
            {
              icon: <FontAwesomeIcon icon={solid('lock')} />,
              label: 'Security',
              link: '/settings/security',
              sidebar: true,
              footer: true,
            },
            {
              icon: <FontAwesomeIcon icon={solid('building')} />,
              label: 'Organization',
              link: '/settings/organization',
              sidebar: true,
              footer: true,
            },
            ...(hasSFEnabled
              ? [
                {
                  icon: <FontAwesomeIcon icon={solid('shopping-cart')} />,
                  label: Services.STOREFRONT,
                  link: '/settings/storefront',
                  route: ['/settings/storefront', '/settings/online-store'],
                  sidebar: true,
                  footer: true,
                },
              ]
              : []),
            ...(hasMCMEnabled
              ? [
                {
                  icon: <FontAwesomeIcon icon={solid('layer-group')} />,
                  label: Services.MARKETPLACE_MANAGEMENT,
                  link: `/settings/${slugster(Services.MARKETPLACE_MANAGEMENT)}`,
                  route: [
                    '/settings/marketplace-management',
                    '/settings/multichannel-management',
                  ],
                  sidebar: true,
                  footer: true,
                },
              ]
              : []),
            {
              icon: <FontAwesomeIcon icon={solid('layer-group')} />,
              label: Services.UNIFIED_ANALYTICS,
              link: `/settings/${slugster(Services.UNIFIED_ANALYTICS)}`,
              route: ['/settings/unified-analytics'],
              sidebar: true,
              footer: true,
            },
            ...(hasAdminToolsEnabled
              ? [
                {
                  component: AdminTools,
                  footer: false,
                  icon: <FontAwesomeIcon icon={solid('cogs')} />,
                  label: 'Admin Tools',
                  link: '/settings/admin-tools',
                  route: ['/settings/admin-tools'],
                  sidebar: false,
                },
              ]
              : []),
          ],
        },
        ...(hasMerchantToolsEnabled
          ? [
            {
              component: FreeToolsRoutes,
              route: '/free-tools/*',
            },
          ]
          : []),
        {
          component: Storefront,
          footer: true,
          icon: <StorefrontIcon />,
          label: Services.STOREFRONT,
          link: '/storefront',
          route: ['/sflogin', '/sfsignup', '/storefront', '/online-store'],
          sidebar: false,
        },
        {
          component: StorefrontEmailSms,
          footer: true,
          icon: <StorefrontIcon />,
          label: 'Email & SMS',
          link: '/email-sms',
          route: '/email-sms/*',
          sidebar: false,
        },
        {
          component: UnifiedAnalyticsApp,
          footer: true,
          icon: <UnifiedAnalyticsIcon />,
          label: Services.UNIFIED_ANALYTICS,
          link: '/unified-analytics/dashboard',
          route: '/unified-analytics/*',
          sidebar: true,
          children: [
            {
              icon: <FontAwesomeIcon icon={solid('tachometer-alt')} />,
              label: 'Dashboard',
              link: '/unified-analytics/dashboard',
              sidebar: true,
              footer: true,
            },

            {
              icon: <FontAwesomeIcon icon={solid('bookmark')} />,
              label: 'Custom Views',
              link: '/unified-analytics/custom-views',
            },

            {
              grouping: 'Settings',
              icon: <FontAwesomeIcon icon={solid('database')} />,
              label: 'Data Connectors',
              children: [
                ...(hasUANewMyConnectorsEnabled
                  ? [
                    {
                      label: 'My Connectors',
                      link: '/unified-analytics/my-connectors',
                      sidebar: true,
                      footer: true,
                    },
                  ]
                  : []),
                {
                  label: 'All Connectors',
                  link: '/unified-analytics/all-connectors',
                  sidebar: true,
                  footer: true,
                },
              ],
            },
            ...(hasUAChannelMappingEnabled
              ? [
                {
                  icon: <FontAwesomeIcon icon={solid('thumbtack')} />,
                  label: 'Channel Mapping',
                  link: '/unified-analytics/channel-mapping',
                  sidebar: true,
                  footer: true,
                },
              ]
              : []),
            ...(hasUAEmailReportsEnabled
              ? [
                {
                  icon: <FontAwesomeIcon icon={solid('tachometer-alt')} />,
                  label: 'Settings',
                  children: [
                    {
                      label: 'Email Reports',
                      link: '/unified-analytics/email-reports',
                      sidebar: true,
                      footer: true,
                    },
                  ],
                },
              ]
              : []),
            ...(hasUAAlertsEnabled
              ? [
                {
                  label: 'Alerts',
                  icon: <FontAwesomeIcon icon={solid('thumbtack')} />,
                  link: '/unified-analytics/alerts/create',
                  sidebar: true,
                  footer: true,
                },
              ]
              : []),
          ],
        },
        {
          component: MarketplaceManagement,
          footer: true,
          icon: <MarketplaceManagementIcon />,
          label: Services.MARKETPLACE_MANAGEMENT,
          link: `/${slugster(Services.MARKETPLACE_MANAGEMENT)}`,
          route: [
            '/marketplace-management',
            `/${slugster(Services.MARKETPLACE_MANAGEMENT)}`,
            '/multichannel-management',
          ],
          sidebar: false,
        },
        {
          component: Reporting,
          footer: true,
          icon: <UnifiedAnalyticsIcon />,
          label: Services.REPORTING,
          link: `/${slugster(Services.REPORTING)}`,
          route: [
            `/${slugster(Services.REPORTING)}`,
          ],
          sidebar: false,
        },
        {
          component: FeedMarketing,
          footer: true,
          icon: <FeedMarketingIcon />,
          label: Services.FEED_MARKETING,
          link: '/feed-marketing',
          route: ['/dfw', '/feed-management/*', '/feed-marketing/*'],
          sidebar: false,
        },
        {
          component: GrowthMarketingRoute,
          footer: false,
          icon: <GrowthMarketingIcon />,
          label: Services.GROWTH_MARKETING,
          link: '/growth-marketing',
          route: ['/growth-marketing', '/marketing-services'],
          sidebar: false,
        },
        {
          component: CustomerEngagementRoute,
          footer: true,
          icon: <CustomerEngagementIcon />,
          label: 'Customer Engagement',
          link: '/customer-engagement',
          route: ['/customer-engagement', '/customer-service'],
          sidebar: false,
        },
        {
          component: MarketplaceServicesRoute,
          footer: false,
          icon: <MarketplaceServicesIcon />,
          label: 'Marketplace Services',
          link: '/marketplace-services',
          route: ['/marketplace-services', '/business-planning'],
          sidebar: false,
        },
        {
          component: DataScienceRoute,
          footer: false,
          icon: <DataScienceIcon />,
          label: 'Data Science Solutions',
          link: '/data-science-solutions',
          route: ['/data-science-solutions', '/data-science'],
          sidebar: false,
        },
        ...(shouldShowGrowthCapital
          ? [
            {
              component: GrowthCapitalRoute,
              footer: false,
              icon: <GrowthCapitalIcon />,
              label: 'Growth Capital',
              link: '/growth-capital',
              route: ['/growth-capital'],
              sidebar: false,
            },
          ]
          : []),
      ],
    },
  ]
}
